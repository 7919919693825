import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as adsoffgDQwSyqvM6Meta } from "/opt/build/repo/pages/adsoff.vue?macro=true";
import { default as adtestz7oH2KNRmWMeta } from "/opt/build/repo/pages/adtest.vue?macro=true";
import { default as adtest2M6Sei4tNv5Meta } from "/opt/build/repo/pages/adtest2.vue?macro=true";
import { default as _91_91term_93_937oyaXUrpA5Meta } from "/opt/build/repo/pages/browse/[[term]].vue?macro=true";
import { default as _91_91id_93_93baAmgZz4KTMeta } from "/opt/build/repo/pages/chats/[[id]].vue?macro=true";
import { default as _91_91id_93_93cjIQB5RWmuMeta } from "/opt/build/repo/pages/chitchat/[[id]].vue?macro=true";
import { default as _91_91id_93_93eoW4CNWXHnMeta } from "/opt/build/repo/pages/communityevent/[[id]].vue?macro=true";
import { default as _91_91groupid_93_93Pcur6WyBETMeta } from "/opt/build/repo/pages/communityevents/[[groupid]].vue?macro=true";
import { default as disclaimercbAuCFHnKSMeta } from "/opt/build/repo/pages/disclaimer.vue?macro=true";
import { default as donatecFf5ywLGUZMeta } from "/opt/build/repo/pages/donate.vue?macro=true";
import { default as donatedjVJQSBNlM5Meta } from "/opt/build/repo/pages/donated.vue?macro=true";
import { default as engagehkTwYUaSGyMeta } from "/opt/build/repo/pages/engage.vue?macro=true";
import { default as _91_91id_93_93ewUf9vt0pJMeta } from "/opt/build/repo/pages/explore/[[id]].vue?macro=true";
import { default as _91_91msgid_93_93UaZ3nzznJRMeta } from "/opt/build/repo/pages/explore/[groupid]/[[msgid]].vue?macro=true";
import { default as _91_91id_93_93q7kcga9opuMeta } from "/opt/build/repo/pages/explore/join/[[id]].vue?macro=true";
import { default as _91place_93p08uGA6eSiMeta } from "/opt/build/repo/pages/explore/place/[place].vue?macro=true";
import { default as _91region_9354uwiBXtsuMeta } from "/opt/build/repo/pages/explore/region/[region].vue?macro=true";
import { default as index6o45EBPOqPMeta } from "/opt/build/repo/pages/find/index.vue?macro=true";
import { default as whereamign8GiXPK77Meta } from "/opt/build/repo/pages/find/whereami.vue?macro=true";
import { default as whoamivJ9PYYFVrtMeta } from "/opt/build/repo/pages/find/whoami.vue?macro=true";
import { default as forgotUwPgolUkcoMeta } from "/opt/build/repo/pages/forgot.vue?macro=true";
import { default as giftaidTgb9chhRCmMeta } from "/opt/build/repo/pages/giftaid.vue?macro=true";
import { default as indexKVvMgLPmCHMeta } from "/opt/build/repo/pages/give/index.vue?macro=true";
import { default as whereamiPCoURtvCHRMeta } from "/opt/build/repo/pages/give/whereami.vue?macro=true";
import { default as whoamiZnFG1NKEDsMeta } from "/opt/build/repo/pages/give/whoami.vue?macro=true";
import { default as googlepayAxc3BlB6PSMeta } from "/opt/build/repo/pages/googlepay.vue?macro=true";
import { default as helpAA37UIVEuEMeta } from "/opt/build/repo/pages/help.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91id_93chQVASeUkCMeta } from "/opt/build/repo/pages/job/[id].vue?macro=true";
import { default as jobskGrf5d8on3Meta } from "/opt/build/repo/pages/jobs.vue?macro=true";
import { default as maintenance0n2g3CNJhCMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as mergeFuNrIwWPplMeta } from "/opt/build/repo/pages/merge.vue?macro=true";
import { default as _91id_93xSQo07kXMDMeta } from "/opt/build/repo/pages/message/[id].vue?macro=true";
import { default as mobilez50oEGgk70Meta } from "/opt/build/repo/pages/mobile.vue?macro=true";
import { default as mydata78UEcNHtOtMeta } from "/opt/build/repo/pages/mydata.vue?macro=true";
import { default as _91_91action_93_93xf0GTG8bzGMeta } from "/opt/build/repo/pages/mypost/[id]/[[action]].vue?macro=true";
import { default as mypostsy8L0HsC1jEMeta } from "/opt/build/repo/pages/myposts.vue?macro=true";
import { default as NationalReuseDayCXQOusct1GMeta } from "/opt/build/repo/pages/NationalReuseDay.vue?macro=true";
import { default as _91_91id_93_93Dzj870aQu5Meta } from "/opt/build/repo/pages/noticeboards/[[id]].vue?macro=true";
import { default as _91_91id_93_93p6HcHl7If3Meta } from "/opt/build/repo/pages/noticeboards/authority/[[id]].vue?macro=true";
import { default as _91_91key_93_93esnXCuxdGlMeta } from "/opt/build/repo/pages/one-click-unsubscribe/[[uid]]/[[key]].vue?macro=true";
import { default as paypalcompetitiondCQKUaNIxQMeta } from "/opt/build/repo/pages/paypalcompetition.vue?macro=true";
import { default as postDmud5c8PYEMeta } from "/opt/build/repo/pages/post.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as _91id_93qkxQDDw0xUMeta } from "/opt/build/repo/pages/profile/[id].vue?macro=true";
import { default as promoteHp0oYbmJXVMeta } from "/opt/build/repo/pages/promote.vue?macro=true";
import { default as _91key_93i5HLpWVEHjMeta } from "/opt/build/repo/pages/settings/confirmmail/[key].vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as _91id_93ZsZLaxbYQeMeta } from "/opt/build/repo/pages/shortlink/[id].vue?macro=true";
import { default as _91id_93GBmmpiNallMeta } from "/opt/build/repo/pages/shortlinks/[id].vue?macro=true";
import { default as indexBBl6OCnZ9nMeta } from "/opt/build/repo/pages/shortlinks/index.vue?macro=true";
import { default as _91_91groupname_93_93UKhA9Cj7q7Meta } from "/opt/build/repo/pages/stats/[[groupname]].vue?macro=true";
import { default as authoritiesueSHdfhf8jMeta } from "/opt/build/repo/pages/stats/authorities.vue?macro=true";
import { default as _91_91id_93_93LH8PbgDYtPMeta } from "/opt/build/repo/pages/stats/authority/[[id]].vue?macro=true";
import { default as heatmapJOjqH3HhWPMeta } from "/opt/build/repo/pages/stats/heatmap.vue?macro=true";
import { default as _91_91groupid_93_93biS8ZFsOpyMeta } from "/opt/build/repo/pages/stories/[[groupid]].vue?macro=true";
import { default as _91id_93kG4JhMRcXMMeta } from "/opt/build/repo/pages/stories/authority/[id].vue?macro=true";
import { default as summaryuNiNQiUpPOMeta } from "/opt/build/repo/pages/stories/summary.vue?macro=true";
import { default as _91id_933Y8oB8nXaCMeta } from "/opt/build/repo/pages/story/[id].vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as _91_91id_93_93pqackFhTKsMeta } from "/opt/build/repo/pages/unsubscribe/[[id]].vue?macro=true";
import { default as unsubscribedtxS6ngdPnqMeta } from "/opt/build/repo/pages/unsubscribe/unsubscribed.vue?macro=true";
import { default as _91_91id_93_93ZTmAUhyLbhMeta } from "/opt/build/repo/pages/volunteering/[[id]].vue?macro=true";
import { default as _91_91groupid_93_93CP4OOuv84tMeta } from "/opt/build/repo/pages/volunteerings/[[groupid]].vue?macro=true";
import { default as yahoologinttqmpzN1hqMeta } from "/opt/build/repo/pages/yahoologin.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "adsoff",
    path: "/adsoff",
    component: () => import("/opt/build/repo/pages/adsoff.vue").then(m => m.default || m)
  },
  {
    name: "adtest",
    path: "/adtest",
    component: () => import("/opt/build/repo/pages/adtest.vue").then(m => m.default || m)
  },
  {
    name: "adtest2",
    path: "/adtest2",
    component: () => import("/opt/build/repo/pages/adtest2.vue").then(m => m.default || m)
  },
  {
    name: "browse-term",
    path: "/browse/:term?",
    meta: _91_91term_93_937oyaXUrpA5Meta || {},
    alias: ["/communities"],
    component: () => import("/opt/build/repo/pages/browse/[[term]].vue").then(m => m.default || m)
  },
  {
    name: "chats-id",
    path: "/chats/:id?",
    meta: _91_91id_93_93baAmgZz4KTMeta || {},
    component: () => import("/opt/build/repo/pages/chats/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "chitchat-id",
    path: "/chitchat/:id?",
    meta: _91_91id_93_93cjIQB5RWmuMeta || {},
    component: () => import("/opt/build/repo/pages/chitchat/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "communityevent-id",
    path: "/communityevent/:id?",
    component: () => import("/opt/build/repo/pages/communityevent/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "communityevents-groupid",
    path: "/communityevents/:groupid?",
    component: () => import("/opt/build/repo/pages/communityevents/[[groupid]].vue").then(m => m.default || m)
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    component: () => import("/opt/build/repo/pages/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/opt/build/repo/pages/donate.vue").then(m => m.default || m)
  },
  {
    name: "donated",
    path: "/donated",
    component: () => import("/opt/build/repo/pages/donated.vue").then(m => m.default || m)
  },
  {
    name: "engage",
    path: "/engage",
    component: () => import("/opt/build/repo/pages/engage.vue").then(m => m.default || m)
  },
  {
    name: "explore-id",
    path: "/explore/:id?",
    component: () => import("/opt/build/repo/pages/explore/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "explore-groupid-msgid",
    path: "/explore/:groupid()/:msgid?",
    component: () => import("/opt/build/repo/pages/explore/[groupid]/[[msgid]].vue").then(m => m.default || m)
  },
  {
    name: "explore-join-id",
    path: "/explore/join/:id?",
    meta: _91_91id_93_93q7kcga9opuMeta || {},
    component: () => import("/opt/build/repo/pages/explore/join/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "explore-place-place",
    path: "/explore/place/:place()",
    component: () => import("/opt/build/repo/pages/explore/place/[place].vue").then(m => m.default || m)
  },
  {
    name: "explore-region-region",
    path: "/explore/region/:region()",
    component: () => import("/opt/build/repo/pages/explore/region/[region].vue").then(m => m.default || m)
  },
  {
    name: "find",
    path: "/find",
    component: () => import("/opt/build/repo/pages/find/index.vue").then(m => m.default || m)
  },
  {
    name: "find-whereami",
    path: "/find/whereami",
    component: () => import("/opt/build/repo/pages/find/whereami.vue").then(m => m.default || m)
  },
  {
    name: "find-whoami",
    path: "/find/whoami",
    component: () => import("/opt/build/repo/pages/find/whoami.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    component: () => import("/opt/build/repo/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "giftaid",
    path: "/giftaid",
    meta: giftaidTgb9chhRCmMeta || {},
    component: () => import("/opt/build/repo/pages/giftaid.vue").then(m => m.default || m)
  },
  {
    name: "give",
    path: "/give",
    component: () => import("/opt/build/repo/pages/give/index.vue").then(m => m.default || m)
  },
  {
    name: "give-whereami",
    path: "/give/whereami",
    component: () => import("/opt/build/repo/pages/give/whereami.vue").then(m => m.default || m)
  },
  {
    name: "give-whoami",
    path: "/give/whoami",
    component: () => import("/opt/build/repo/pages/give/whoami.vue").then(m => m.default || m)
  },
  {
    name: "googlepay",
    path: "/googlepay",
    component: () => import("/opt/build/repo/pages/googlepay.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/opt/build/repo/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "job-id",
    path: "/job/:id()",
    meta: _91id_93chQVASeUkCMeta || {},
    component: () => import("/opt/build/repo/pages/job/[id].vue").then(m => m.default || m)
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/opt/build/repo/pages/jobs.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "merge",
    path: "/merge",
    component: () => import("/opt/build/repo/pages/merge.vue").then(m => m.default || m)
  },
  {
    name: "message-id",
    path: "/message/:id()",
    component: () => import("/opt/build/repo/pages/message/[id].vue").then(m => m.default || m)
  },
  {
    name: "mobile",
    path: "/mobile",
    component: () => import("/opt/build/repo/pages/mobile.vue").then(m => m.default || m)
  },
  {
    name: "mydata",
    path: "/mydata",
    component: () => import("/opt/build/repo/pages/mydata.vue").then(m => m.default || m)
  },
  {
    name: "mypost-id-action",
    path: "/mypost/:id()/:action?",
    meta: _91_91action_93_93xf0GTG8bzGMeta || {},
    component: () => import("/opt/build/repo/pages/mypost/[id]/[[action]].vue").then(m => m.default || m)
  },
  {
    name: "myposts",
    path: "/myposts",
    meta: mypostsy8L0HsC1jEMeta || {},
    component: () => import("/opt/build/repo/pages/myposts.vue").then(m => m.default || m)
  },
  {
    name: "NationalReuseDay",
    path: "/NationalReuseDay",
    meta: NationalReuseDayCXQOusct1GMeta || {},
    component: () => import("/opt/build/repo/pages/NationalReuseDay.vue").then(m => m.default || m)
  },
  {
    name: "noticeboards-id",
    path: "/noticeboards/:id?",
    component: () => import("/opt/build/repo/pages/noticeboards/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "noticeboards-authority-id",
    path: "/noticeboards/authority/:id?",
    component: () => import("/opt/build/repo/pages/noticeboards/authority/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "one-click-unsubscribe-uid-key",
    path: "/one-click-unsubscribe/:uid?/:key?",
    component: () => import("/opt/build/repo/pages/one-click-unsubscribe/[[uid]]/[[key]].vue").then(m => m.default || m)
  },
  {
    name: "paypalcompetition",
    path: "/paypalcompetition",
    component: () => import("/opt/build/repo/pages/paypalcompetition.vue").then(m => m.default || m)
  },
  {
    name: "post",
    path: "/post",
    component: () => import("/opt/build/repo/pages/post.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    component: () => import("/opt/build/repo/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "promote",
    path: "/promote",
    meta: promoteHp0oYbmJXVMeta || {},
    component: () => import("/opt/build/repo/pages/promote.vue").then(m => m.default || m)
  },
  {
    name: "settings-confirmmail-key",
    path: "/settings/confirmmail/:key()",
    meta: _91key_93i5HLpWVEHjMeta || {},
    component: () => import("/opt/build/repo/pages/settings/confirmmail/[key].vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexoWr0YesFbSMeta || {},
    component: () => import("/opt/build/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "shortlink-id",
    path: "/shortlink/:id()",
    component: () => import("/opt/build/repo/pages/shortlink/[id].vue").then(m => m.default || m)
  },
  {
    name: "shortlinks-id",
    path: "/shortlinks/:id()",
    component: () => import("/opt/build/repo/pages/shortlinks/[id].vue").then(m => m.default || m)
  },
  {
    name: "shortlinks",
    path: "/shortlinks",
    meta: indexBBl6OCnZ9nMeta || {},
    component: () => import("/opt/build/repo/pages/shortlinks/index.vue").then(m => m.default || m)
  },
  {
    name: "stats-groupname",
    path: "/stats/:groupname?",
    component: () => import("/opt/build/repo/pages/stats/[[groupname]].vue").then(m => m.default || m)
  },
  {
    name: "stats-authorities",
    path: "/stats/authorities",
    component: () => import("/opt/build/repo/pages/stats/authorities.vue").then(m => m.default || m)
  },
  {
    name: "stats-authority-id",
    path: "/stats/authority/:id?",
    component: () => import("/opt/build/repo/pages/stats/authority/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "stats-heatmap",
    path: "/stats/heatmap",
    component: () => import("/opt/build/repo/pages/stats/heatmap.vue").then(m => m.default || m)
  },
  {
    name: "stories-groupid",
    path: "/stories/:groupid?",
    component: () => import("/opt/build/repo/pages/stories/[[groupid]].vue").then(m => m.default || m)
  },
  {
    name: "stories-authority-id",
    path: "/stories/authority/:id()",
    component: () => import("/opt/build/repo/pages/stories/authority/[id].vue").then(m => m.default || m)
  },
  {
    name: "stories-summary",
    path: "/stories/summary",
    component: () => import("/opt/build/repo/pages/stories/summary.vue").then(m => m.default || m)
  },
  {
    name: "story-id",
    path: "/story/:id()",
    component: () => import("/opt/build/repo/pages/story/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "unsubscribe-id",
    path: "/unsubscribe/:id?",
    component: () => import("/opt/build/repo/pages/unsubscribe/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "unsubscribe-unsubscribed",
    path: "/unsubscribe/unsubscribed",
    component: () => import("/opt/build/repo/pages/unsubscribe/unsubscribed.vue").then(m => m.default || m)
  },
  {
    name: "volunteering-id",
    path: "/volunteering/:id?",
    component: () => import("/opt/build/repo/pages/volunteering/[[id]].vue").then(m => m.default || m)
  },
  {
    name: "volunteerings-groupid",
    path: "/volunteerings/:groupid?",
    component: () => import("/opt/build/repo/pages/volunteerings/[[groupid]].vue").then(m => m.default || m)
  },
  {
    name: "yahoologin",
    path: "/yahoologin",
    meta: yahoologinttqmpzN1hqMeta || {},
    component: () => import("/opt/build/repo/pages/yahoologin.vue").then(m => m.default || m)
  }
]